<template>
  <b-modal
    ref="modal-warranty-bill-excel"
    hide-footer
    :title="titleExcel"
    id="modal-warranty-bill-excel"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <b-form-group label="Danh sách cần xuất:">
          <b-form-radio-group v-model="selectedListExcel">
            <b-form-radio value="all">Tất cả các trang</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-row v-if="selectedListExcel === 'all'">
          <b-col md="4">
            <b-form-group>
              <label>Từ ngày:</label>
              <date-picker
                placeholder="Từ ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.fromDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Đến ngày:</label>
              <date-picker
                placeholder="Tới ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.toDate"
              ></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      :show="isDownloading"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      @hidden="onHiddenOverlay"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            variant="light"
            small
            type="grow"
          ></b-spinner>
        </div>
      </template>
      <b-button
        variant="primary"
        size="sm"
        @click="exportToExcelStream"
      >
        <v-icon
          small
          class="text-white"
          v-b-tooltip
        >mdi-download</v-icon>
        <strong>Xuất Excel</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('modal-warranty-bill-excel')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<style scoped>
div /deep/ .modal-backdrop {
  opacity: 0.5;
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { makeToastFaile } from '@/utils/common';
import { BASE_URL } from '@/utils/constants';
import axios from 'axios';
import fileDownload from '@/utils/file-download';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import moment from 'moment';
import timeUtils from '@/utils/date';

export default {
  props: ['param', 'mode'],
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: null,
      selectedListExcel: 'all',
      apiParams: null,
      titleExcel: 'Xuất excel hoá đơn bảo hành',
      types: [],
      isDownloading: false,
      selectedMisa: false,
    };
  },
  created() {
    this.dpConfigs = timeUtils.DP_CONFIG;
  },
  components: {
    datePicker,
  },
  watch: {
    $props: {
      handler() {
        switch (this.mode) {
          case 5:
            this.titleExcel = 'Xuất excel hoá đơn bảo hành';
            break;
          default:
            this.titleExcel = 'Không xác định';
            break;
        }
        this.apiParams = { ...this.param };
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    onHiddenOverlay() {
      if (this.$refs.button) {
        this.$refs.button.focus();
      }
    },
    checkPermissions,
    exportToExcelStream() {
      this.isDownloading = true;

      const baseUrl = BASE_URL;

      const apiParams = this.getParamFilters();

      if (!apiParams) {
        this.isDownloading = false;
        return;
      }
      axios
        .get(`${baseUrl}bills/export-warranty-excel`, {
          params: {
            ...apiParams,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
          this.isDownloading = false;
        })
        .catch((err) => {
          this.isDownloading = false;
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }

          this.$nprogress.done();
        });
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    getParamFilters() {
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';
      const fromDate = this.apiParams.fromDate
        ? moment(this.apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : moment().subtract(7, 'd').format('YYYY-MM-DD');

      const toDate = this.apiParams.toDate
        ? moment(this.apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : moment().format('YYYY-MM-DD');

      const warrantyReasonId = this.apiParams.warrantyReasonId || null;

      const billStatus = this.apiParams.status || null;
      const storeIds = this.apiParams.storeIds || null;

      const apiParams = {
        fromDate: fromDate,
        toDate: toDate,
        warrantyReasonId: warrantyReasonId,
        status: billStatus,
        storeIds: storeIds,
      };

      const diff = this.getDaysDiff(apiParams.fromDate, apiParams.toDate);
      if (diff > 32) {
        makeToastFaile('Vui lòng chọn khoảng thời gian trong vòng 1 tháng!');
        return null;
      }

      return apiParams;
    },
    getDaysDiff(startDate, endDate, dateFormat = 'YYYY-MM-DD') {
      const getDateAsArray = (date) => {
        return moment(date.split(/\D+/), dateFormat);
      };
      return (
        getDateAsArray(endDate).diff(getDateAsArray(startDate), 'days') + 1
      );
    },
  },
};
</script>
